const pageRoute = [
    {
        path: 'companyIntro',
        name: 'CompanyIntro',
        component: resolve => require(['@/views/CompanyIntro.vue'],resolve),
        meta: {
            title: '公司介绍'
        }
    },
    {
        path: 'productIntro',
        name: 'ProductIntro',
        component: resolve => require(['@/views/ProductIntro.vue'],resolve),
        meta: {
            title: '产品'
        }
    },
    {
        path: 'caseShare',
        name: 'CaseShare',
        component: resolve => require(['@/views/CaseShare.vue'],resolve),
        meta: {
            title: '案例'
        }
    },
    {
        path: 'contactUs',
        name: 'ContactUs',
        component: resolve => require(['@/views/ContactUs.vue'],resolve),
        meta: {
            title: '联系我们'
        }
    }
]
export default pageRoute