<template>
    <div class="img-box" :class="index % 2 == 0 ? 'img-box-down' : 'img-box-up'">
        <template v-if="index % 2 != 0">
            <div class="img-area" :style="{ background: 'url(' + item.img + ') center/cover'}">
            </div>
            <div class="desc-box">
                <div class="desc-box-time">{{ item.time }}</div>
                <div class="desc-box-title">{{ item.title }}</div>
                <div class="desc-box-desc">{{ item.desc }}</div>
            </div>
        </template>
        <template v-else>
            <div class="desc-box">
                <div class="desc-box-time">{{ item.time }}</div>
                <div class="desc-box-title">{{ item.title }}</div>
                <div class="desc-box-desc">{{ item.desc }}</div>
            </div>
             <div class="img-area" :style="{ background: 'url(' + item.img + ') center/cover'}">
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'ActivityItem',
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        index: {
            type: Number,
            default: 0
        }
    }
}
</script>
<style lang="less" scoped>
.img-box {
    flex-shrink: 0;
    width: 30%;
    cursor: pointer;
    background: #f2f2f2;
    height: 300px;
    overflow: hidden;
    position: relative;
}
.img-area {
    height: 60%;
    width: 100%;
    transition: all 0.4s;
    position: absolute;
}
.desc-box {
    height: 200px;
    padding: 14px 14px 0;
    height: 100%;
    transition: all 0.4s;
    position: absolute;
}
.desc-box-time {
    color: #888888;
}
.desc-box-title {
    color: #666666;
    padding-top: 30px;
    font-size: 20px;
    font-weight: 600;
}
.desc-box-desc {
    opacity: 0;
    height: 0;
    color: #888888;
    padding-top: 10px;
    transition: all 0.4s;
}
// 向上滑动
.img-box-up {
    .img-area {
        top: 0;
    }
    .desc-box {
        top: 60%;
    }
}
// 向下滑动
.img-box-down {
    .img-area {
        bottom: 0;
    }
    .desc-box {
        bottom: 0;
    }
}
.img-box:hover{
    .img-area {
        opacity: 0;
    }
    .desc-box-desc {
        height: 60%;
        opacity: 1;
    }
}
.img-box:hover.img-box-up {
        .img-area {
            top: -60%;
        }
        .desc-box {
            top: 0;
        }
    }
 .img-box:hover.img-box-down {
        .img-area {
            bottom: -60%;
        }
    }

</style>