<template>
    <div class="home-page">
        <CarouselList :imgList="imgList"></CarouselList>
        <TextDivider orientation="left" width="calc(80vw)"></TextDivider>
        <div class="main-img-list">
            <div class="img-box" v-for="(img,imgIndex) in mainImgList" :key="imgIndex">
                <img  :src="img" alt="" loading="lazy">
            </div>
        </div>
        <div class="life-style">
            <TextDivider class="img-font" textString="定制你的专属生活" orientation="left" width="calc(80vw)" fontColor="#ffffff"></TextDivider>
            <div class="life-style-intro">
                <div class="intro-item" v-for="(item,index) in introList" :key="index">
                    <div class="intro-title">{{item.title}}</div>
                </div>
            </div>
        </div>
        <div class="product-recommend">
            <TextDivider class="img-font" textString="新品推荐" orientation="left" width="calc(80vw)" fontColor="#ffffff" topContent="NEWPRODUCTS">
            </TextDivider>
            <div class="product-recommend-box">
                <div class="big-box">
                    <img src="@/assets/imgs/homepage_5.jpg" alt="" loading="lazy">
                    <div class="img-title">P138断桥窗纱一体平开窗</div>
                </div>
                <div class="small-box">
                    <div>
                        <img src="@/assets/imgs/homepage_6.jpg" alt="" loading="lazy">
                        <div class="img-title">p120H窄边框窗纱一体平开窗</div>
                    </div>
                    <div>
                        <img src="@/assets/imgs/homepage_7.jpg" alt="" loading="lazy">
                        <div class="img-title">N16窄边框卫浴门</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-activity">
            <TextDivider class="img-font" textString="品牌活动" orientation="left" width="calc(80vw)" desc="***品牌时事热点" topContent="ACTIVITIES">
            </TextDivider>
        </div>
        <div class="main-img-list">
            <ActivityItem v-for="(item, index) in activityImgList" :key="index" :item="item" :index="index + 1"></ActivityItem>
        </div>
    </div>
</template>
<script>
import TextDivider from '@/components/TextDivider.vue'
import ActivityItem from '@/components/ActivityItem.vue'
import CarouselList from '@/components/CarouselList.vue'
export default {
    name: 'HomePage',
    components: { TextDivider, ActivityItem, CarouselList },
    data() {
        return {
            imgList: [require('@/assets/imgs/3.jpg'),require('@/assets/imgs/1.jpg')],
            mainImgList: [require('@/assets/imgs/homepage_2.jpg'),require('@/assets/imgs/homepage_1.jpg'),require('@/assets/imgs/homepage_3.jpg')],
            introList: [{title: '阳光房'}, {title: '入户'},{title: '阳台'}, {title: '厨卫'}],
            activityImgList: [
                {
                    img: require('@/assets/imgs/homepage_8.jpg'),
                    title: 'M16窄边框推拉门',
                    time: '2024/07/06',
                    desc: '型材表面采用先进的表面工艺,表面饱满平滑;配置专用拉手,结构简约、美观'
                },
                {
                    img: require('@/assets/imgs/homepage_9.jpg'),
                    title: 'P85型F系列纱扇一体双内开窗',
                    time: '2024/11/08',
                    desc: '标配OPLV自主配套研发专用五金，与门窗完美切合，实现全系统配置，更安全，更耐用'
                },
                {
                    img: require('@/assets/imgs/homepage_10.jpg'),
                    title: 'N7730折叠门',
                    time: '2024/11/08',
                    desc: '采用防夹手密封皮条设计,更具人性化考虑;隐藏式合页,结构新颖,开合顺畅,外观更时尚;'
                }
            ]
        }
    },

}
</script>
<style lang="less" scoped>
.home-page {
    padding-bottom: 80px;
}
.main-img-list {
    padding: 0 100px;
    display: flex;
    justify-content: space-around;
    .img-box {
        flex-shrink: 0;
        width: 30%;
        overflow: hidden;
        cursor: pointer;
    }
    img {
        width: 100%;
        transition: all 0.4s;
    }
    img:hover {
        transform: scale(1.2);
    }
}
.life-style {
    margin-top: 20px;
    height: 600px;
    background: url('~@/assets/imgs/homepage_4.jpg') center/cover;
    
    .life-style-intro {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 40px;
        padding: 0 20px;
    }
    .intro-item {
        border-radius: 50%;
        transition: all 0.4s;
        background: rgba(255,255,255,0.4);
        height: 120px;
        width: 120px;
        cursor: pointer;
    }
    .intro-item:hover{
        background: #fbc200;
    }
    .intro-title {
        width: 100%;
        height: 100%;
        color: #ffffff;
        font-size: 20px;
        text-align: center;
        
        line-height: 120px;
    }
}
.product-recommend {
    background: url('~@/assets/imgs/hpitem.jpg') center/cover;
    .product-recommend-box {
        padding: 0 10vw;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        img {
            width: 100%;
            height: 100%;
            transition: all 0.2s;
            &:hover {
                transform: scale(1.2)
            }
        }
    }
    .big-box {
        flex: 2;
    }
    .small-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .big-box,.small-box > div {
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }
    .img-title {
        color: #ffffff;
        position: absolute;
        bottom: 10px;
        text-align: center;
        width: 100%;
    }
}
@media screen and (max-width: 600px){
    .main-img-list {
        padding: 0 20px;
        flex-direction: column;
        gap: 20px;
        .img-box {
            width: 100%;
        }
    }
    .life-style-intro {
        padding-top: 100px !important;
    }
    .english-intro {
        padding-top: 40px;
    }
    .product-recommend-box {
        display: block !important;
    }
    .big-box {
        margin-bottom: 20px;
    }
    .small-box {
        flex-direction: row !important;
    }
}

</style>