export const menuData = [
    {
        title: '首页',
        path: '/home'
    },
    {
        title: '公司介绍',
        path: '/page/companyIntro'
    },
    {
        title: '产品',
        path: '/page/productIntro'
    },
    {
        title: '案例',
        path: '/page/caseShare'
    },
    {
        title: '联系我们',
        path: '/page/ContactUs'
    }
]