<template>
    <div class="text-divider" :style="{width: width, '--height': topContent ? '200px' : '100px'}">
        <div style="flex:1">
            <slot name="topContent">
                <div class="english-intro" :style="{'--color': fontColor,'text-align': orientation}">{{ topContent }}</div>
            </slot>
            <a-divider class="text-divider-content" :style="{'--color': fontColor}" :orientation="orientation">
                {{ textString }}
            </a-divider>
            <slot name="desc" >
                <div class="text-desc">{{ desc }}</div>
            </slot>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'TextDivider',
    props: {
        textString: {
            type: String,
            default:'打造绿色建筑 可持续发展'
        },
        orientation: {
            type: String,
            default: 'left'
        },
        width: {
            type: String,
            default: '100vw'
        },
        fontColor: {
            type: String,
            default: 'rgba(0,0,0,.85)'
        },
        desc: {
            type: String,
            default: ''
        },
        topContent: {
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="less" scoped>
.text-divider {
    height: 300px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.text-divider-content {
    font-family: fangsong;
}
.text-desc {
    color: #888888;
}
.english-intro {
    font-size: 22px;
    font-weight: 600;
    color: var(--color);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-family: serif;
}
/deep/.ant-divider-inner-text {
    font-size: 36px !important;
    color: var(--color) !important;
}
@media screen and (max-width: 600px){
    .text-divider {
        height: var(--height);
    }
    /deep/.ant-divider-inner-text {
        font-size: 14px !important;
    }
}
</style>