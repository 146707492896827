<template>
    <div class="footer-area">
        <div class="qr-code-box">
            <div class="qr-code">
                <img src="@/assets/imgs/qrCode.png" alt="">
            </div>
            <div class="entname">广州兴瑞达门窗科技有限公司  </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FooterArea'
}
</script>
<style lang="less" scoped>
.footer-area {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.qr-code-box {
    width: 200px;
    text-align: center;
    color: #777777;
    font-size: 12px;
}  
    
.qr-code {
    height: 120px;
    width: 120px;
    margin: 0 auto;
    background: #ffffff;
    img{
        height: 120px;
    }
}
.entname {
    padding-top: 10px;
}
</style>