<template>
    <div class="nav-list">
        <div class="mobile-nav-icon" @click="getMobileNav">
            <a-icon type="align-left" style="color: #ffffff;fontSize:40px;" />
        </div>
        <div class="nav-list-left">
            <div class="logo">X</div>
            <div class="entname">广州兴瑞达门窗科技有限公司</div>
        </div>
        <div class="nav-list-right">
            <div class="nav-item" :class="{active:$route.path == item.path}" v-for="(item,index) in menuData" :key="index" @click="goPage(item.path)">{{item.title}}</div>
        </div>
        <a-drawer
            title=""
            placement="left"
            :visible="mobileMenu"
            @close="mobileMenu = false"
            style="backGround: #111111;"
        >
            <div>
                <div class="nav-item" :class="{active:$route.path == item.path}" v-for="(item,index) in menuData" :key="index" @click="goPage(item.path)">{{item.title}}</div>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import { menuData } from '@/views/menu.js'
export default {
    name: 'NavList',
    data() {
        return {
            menuData,
            mobileMenu: false
        }
    },
    methods: {
        goPage(path) {
            if(path == this.$route.path || !path) {
                return
            }
            this.mobileMenu = false
            this.$router.push(path)
        },
        getMobileNav() {
            this.mobileMenu = !this.mobileMenu
        }
    }
}
</script>
<style lang="less" scoped>
.nav-list {
    height: 100%;
    padding: 0 30px;
    background: rgba(0,0,0,0.9);
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.mobile-nav-icon {
    display: none;
    height: 40px;
    width: 40px;
}
.nav-list-left {
    display: flex;
    align-items: center;
    color: #fbc200;
}
.logo {
    height: 34px;
    width: 34px;
    background: #ffffff;
    border-radius: 50%;
    font-size: 26px;
    text-align: center;
    margin-right: 12px;
}
.entname{
    font-size: 32px;
    font-weight: 600;
}
.nav-list-right {
    display: flex;
    align-items: center;
}
.nav-item {
    font-size: 22px;
    color: #ffffff;
    margin-right: 28px;
    cursor: pointer;
}
.active {
    position: relative;
    color: #fbc200;
}
/deep/ .ant-drawer-wrapper-body {
    background: rgb(0, 0, 0);
    .nav-item {
        font-size: 18px;
    }
}
@media screen and (max-width: 600px) {
    .logo{
        display: none;
    }
    .entname{
        font-size: 18px;
    }
    .nav-list {
        padding: 0 10px;
    }
    .mobile-nav-icon {
        display: block;
    }
    .nav-list-right {
        display: none;
    }
    .nav-item {
        font-size: 18px !important;
        line-height: 50px;
        border-bottom: 1px dashed;
    }
}
</style>