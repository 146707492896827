import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@/views/HomePage.vue";
import Frame from "@/components/Frame.vue"
import pageRoute from "./pageRoute"
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/page',
        component: Frame,
        children: pageRoute
    }
]
const router = new VueRouter({
    routes,
    // 对于页面跳转，全部都返回到页面顶部。
})
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.getElementsByClassName('app-main-page')[0] && (document.getElementsByClassName('app-main-page')[0].scrollTop = 0)
    next();
  });
export default router