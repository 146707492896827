<template>
    <div>
       <section class="app-main-page">
           <nav class="app-nav">
               <NavList></NavList>
           </nav>
           <section class="app-main-body">
               <div id="particles-js"></div>
               <router-view />
               <div class="app-footer">
                 <FooterArea></FooterArea>
               </div>
           </section>
           
       </section>        
    </div>
</template>
<script>
import NavList from '@/components/NavList.vue'
import FooterArea from '@/components/FooterArea.vue'
export default {
    name:'MainPage',
    components: { NavList, FooterArea },
    created(){
        $.getJSON("js/particle.json", function(data) {
            particlesJS("particles-js", data);
        });
    }

}
</script>
<style lang="less" scoped>
.app-main-page {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}
.app-main-body{
    width: 100%;
    height: 100%;
    color: rgb(0, 0, 0);
}
#particles-js {
    position: fixed;
    height: 100%;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
}
.app-nav {
    height: 80px;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
}
.app-footer {
    height: 340px;
    background: #111111;
}
</style>